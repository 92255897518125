<template>
    <main class="main">

        <nav aria-label="breadcrumb" class="breadcrumb-nav mb-2">
            <div class="container">
                <ol class="breadcrumb">
                    <li class="breadcrumb-item">
                        <router-link to="/">{{ $t('home') }}</router-link>
                    </li>
                    <!-- <li class="breadcrumb-item">
                        <router-link to="/shop">{{ $t('shop') }}</router-link>
                    </li> -->
                    <li class="breadcrumb-item active">{{ $t(pageTitle) }}</li>
                    <li class="breadcrumb-item" v-if="$route.query.q">
                        <span>{{ $t('search') }} - {{ $route.query.q }}</span>
                    </li>
                </ol>
                <h4>{{ $t('search') }}</h4>
            </div>
        </nav>

        <div class="page-content">
            <div class="container">
                <p class="no-results" v-if="products.length === 0">No product Found.</p>
                <div class="row" v-else>
                    <div class="col-lg-9 skeleton-body skel-shop-products" :class="{ loaded: loaded }">
                        <div class="row">
                            <div class="col-md-12 category-text-seo">
                                <h5> Alle autohouders SEP tekst</h5>
                                <p> Bij Mobile Supplies vind je alle mogelijke accessoires voor je smartphone. Ons
                                    uitgebreide
                                    aanbod
                                    bestaat uit meer dan 10.000 verschillende producten. Of je nu een smartphone hoesje,
                                    een
                                    oplader
                                    of
                                    een kabel zoekt, bij ons vind je het allemaal voor de beste prijs en bestel je
                                    eenvoudig
                                    online.
                                </p>
                                <read-more :text="seoMsg" :max-chars="81"></read-more>
                            </div>
                        </div>

                        <pagination-comp :per-page="perPage" :total="totalCount" :layout-type="layout" :sortBy="sortBy"
                            @handleLayout="handleLayout" @handlePerPage="handlePerPage" @handleSortBy="handleSortBy" />

                        <!-- <p class="no-results" v-if="shop.length === 0 && loaded">No products matching your selection.
                        </p> -->

                        <div class="cat-blocks-container">
                            <div v-if="products.length === 0">
                                <p class="text-center">{{ $t("no_products") }}</p>
                            </div>
                            <div v-else>
                                <template v-if="type !== 'list'">
                                    <div class="row">
                                        <div class="col-6 col-md-4 col-lg-3" v-for="product in paginatedData"
                                            :key="product.url_key">
                                            <!-- <router-link :to="'/productDetails/' + product.url_key" class="w-100"> -->
                                            <product-home :product="product"></product-home>
                                            <!-- </router-link> -->
                                        </div>
                                        <!-- <div class="col-6 col-md-4 col-lg-3">
                                        <product-home :product="product"></product-home>
                                    </div>
                                    <div class="col-6 col-md-4 col-lg-3">
                                        <product-home :product="product"></product-home>
                                    </div>
                                    <div class="col-6 col-md-4 col-lg-3">
                                        <product-home :product="product"></product-home>
                                    </div> -->
                                    </div>
                                </template>

                                <template v-if="type === 'list'">
                                    <div class="row" v-for="product in paginatedData" :key="product.url_key">
                                        <div class="col-12">
                                            <!-- <router-link :to="'/productDetails/' + product.url_key" class="w-100"> -->
                                            <product-home-list :product="product"></product-home-list>
                                            <!-- </router-link> -->
                                        </div>
                                    </div>
                                </template>
                            </div>

                        </div>

                        <pagination-comp :per-page="perPage" :total="totalCount" :layout-type="layout" :sortBy="sortBy"
                            @handleLayout="handleLayout" @handlePerPage="handlePerPage" @handleSortBy="handleSortBy" />

                        <div class="row">
                            <div class="col-md-12 category-text-seo">
                                <h5> Alle smartphone accessoires</h5>
                                <p> Bij Mobile Supplies vind je alle mogelijke accessoires voor je smartphone. Ons
                                    uitgebreide
                                    aanbod
                                    bestaat uit meer dan 10.000 verschillende producten. Of je nu een smartphone hoesje,
                                    een
                                    oplader
                                    of
                                    een kabel zoekt, bij ons vind je het allemaal voor de beste prijs en bestel je
                                    eenvoudig
                                    online.
                                </p>
                                <Read-more :text="seoMsg" :maxChars="81"></Read-more>
                            </div>
                        </div>
                    </div>
                    <!-- sticky-container -->
                    <aside class="col-lg-3 order-lg-first">
                        <!-- v-sticky="!isSidebar" sticky-offset="{ top: 69 }" -->
                        <div v-if="aggregation && aggregation.length > 0">
                            <button class="sidebar-fixed-toggler" @click="toggleSidebar" v-if="isSidebar">
                                <i class="icon-cog"></i>
                            </button>

                            <div class="sidebar-filter-overlay" @click="hideSidebar"></div>
                            <shop-sidebar-one :is-sidebar="isSidebar" :aggregation="this.aggregations"></shop-sidebar-one>
                        </div>
                    </aside>
                </div>
            </div>
        </div>
    </main>
</template>

<script>

import { mapGetters } from 'vuex';
// import Sticky from 'vue-sticky-directive';
// import { homeData } from '@/esf_weert_mobilesupplies/utilities/data';
import ShopSidebarOne from '@/esf_weert_mobilesupplies/core/components/partial/shop/sidebar/ShopSidebarOne';
// import Pagination from '@/esf_weert_mobilesupplies/core/components/elements/ProductPagination';
import ReadMore from '@/esf_weert_mobilesupplies/core/components/elements/ReadMore.vue';
import ProductHome from '@/esf_weert_mobilesupplies/core/components/elements/products/ProductHome';
import ProductHomeList from '@/esf_weert_mobilesupplies/core/components/elements/products/ProductHomeList';
import { getProductsBySearchPreview } from "@storefront/core/data-resolver/products";
import { extractFilters } from "@storefront/core/router/helpers/cleanPath.js";
import config from "@config";
import PaginationComp from '@/esf_weert_mobilesupplies/core/components/elements/PaginationComp';

// import Repository, { baseUrl } from '@/repositories/repository.js';
// import { scrollToPageContent } from '@/utilities/common';


export default {
    components: {
        ShopSidebarOne,
        PaginationComp,
        ProductHome,
        ProductHomeList,
        ReadMore
    },
    // directives: {
    //     Sticky
    // },
    data: function () {
        return {
            searchSuggestions: [],
            searchRecommendations: [],
            closeSignal: {},

            // others
            // perPage: 5,
            // type: 'list',
            // defaultType: 'cols',
            // totalCount: 10,
            // orderBy: 'default',
            products: [],
            pageTitle: "",
            isSidebar: true,
            loaded: false,
            seoMsg: "Het is heel simpel en snel online telefoon accessoires zoeken bij Mobile Supplies. Je vindt gemakkelijk in meer dan 10.000 producten jouw gewenste mobiele telefoon accessoires. Ook als je smartphone stuk is kun je hier bij reparatie onderdelen de juiste onderdelen vinden. Maar als je een leuk hoesje zoekt of gewoon je autolader weer eens kwijt bent vind je bij Mobile Supplies zeker een goede!",

            // pagination
            perPage: 24,
            layout: 'cols',
            totalCount: 0,
            sortBy: 'price_low_high',
            // pagination
        };
    },
    async mounted() {
        const { page } = this.$route.query;
        const { path } = this.$route;
        const query = { ...this.$route.query };

        if (page && this.totalPages < parseInt(page)) {
            delete query["page"];
        }

        const pageSize = config.products?.perPage;
        const perPage = this.$store.getters["search/getProductsPerPage"];
        if (perPage != pageSize) query["page-size"] = perPage;

        this.$router.replace({ path, query });

        this.resizeHandler();
        window.addEventListener('resize', this.resizeHandler, {
            passive: true
        });
        const searchVal = this.$route.query.q;
        await this.loadSearch(searchVal);
    },
    computed: {
        ...mapGetters({
            aggregations: 'product/getAggregations'
        }),
        // pagination
        type: function () {
            return this.layout;
        },
        currentPage: function () {
            return parseInt(this.$route.query.page ? this.$route.query.page : 1);
        },
        paginatedData() {
            if (!this.products) return [];

            let start = (this.currentPage - 1) * this.perPage;
            let end = start + this.perPage;

            // Apply sorting based on sortBy
            let sortedCategories = [...this.products];
            if (this.sortBy === 'price_low_high') {
                sortedCategories.sort((a, b) => a.price - b.price);
            } else if (this.sortBy === 'price_high_low') {
                sortedCategories.sort((a, b) => b.price - a.price);
            }
            // Add more sorting logic if needed

            return sortedCategories.slice(start, end);
        },
        // pagination
        searchWord() {
            if (typeof this.$route.query.q != "undefined") {
                return this.$route.query.q;
            } else {
                return "";
            }
        },
        selectOptions() {
            const sortOpt = [];
            Object.keys(config.sortingOptions).forEach((element) => {
                const option = {
                    value: element,
                    text: this.$t(`sortingOptions.${element}`),
                };
                sortOpt.push(option);
            });
            return sortOpt;
        },
        sorting() {
            return this.$store.getters["search/getSorting"];
        },
        isLoading() {
            return this.$store.getters["search/getLoadProducts"];
        },
        showProducts() {
            return this.$store.getters["search/getShowProducts"];
        },
        productList() {
            if (this.showProducts.length == 0) {
                return [];
            }
            return this.showProducts;
        },
        // currentPage() {
        //     return +this.$store.getters["search/getCurrentPage"];
        // },
        prodsPerPage() {
            return +this.$store.getters["search/getProductsPerPage"];
        },
        totalProducts() {
            return +this.$store.getters["search/getTotalProducts"];
        },
    },
    watch: {
        // $route: function () {},
        searchWord(searchVal) {
            this.loadSearch(searchVal);
            // const { path } = this.$route;
            // const query = { ...this.$route.query };
            // const pageSize = config.products?.perPage;
            // const perPage = this.$store.getters["search/getProductsPerPage"];

            // if (perPage != pageSize) query["page-size"] = perPage;
            // this.$router.push({ path: path, query: query });
        },
    },
    unmounted: function () {
        window.removeEventListener('resize', this.resizeHandler);
    },
    methods: {
        async loadSearch(searchVal) {
            if (searchVal != null && searchVal != "") {
                const controller = new AbortController();
                const { signal } = controller;
                this.closeSignal.products = controller;
                const output = await getProductsBySearchPreview(searchVal, signal, 10);
                this.closeSignal.products = null;
                if (output.search_suggestions)
                    this.searchSuggestions = output.search_suggestions;
                else this.searchSuggestions = [];

                if (output.searchRecommendations)
                    this.searchRecommendations = output.search_recommendations;
                else this.searchRecommendations = [];

                if (output.items) {
                    this.products = output.items;
                    this.totalCount = output.items.length;
                }

                const filter = extractFilters(this.$route);
                await this.$store.dispatch("search/load", {
                    search: searchVal,
                    filter,
                });
                const bcrumb = { current: searchVal, routes: [] };
                this.$store.commit("breadcrumbs/set", bcrumb);
            }
        },
        toggleSidebar: function () {
            if (
                document
                    .querySelector('body')
                    .classList.contains('sidebar-filter-active')
            ) {
                document
                    .querySelector('body')
                    .classList.remove('sidebar-filter-active');
            } else {
                document
                    .querySelector('body')
                    .classList.add('sidebar-filter-active');
            }
        },
        hideSidebar: function () {
            document
                .querySelector('body')
                .classList.remove('sidebar-filter-active');
        },
        resizeHandler: function () {
            if (window.innerWidth > 991) this.isSidebar = false;
            else this.isSidebar = true;
        },
        changeSorting(event) {
            const sortVal = event.target.value;
            this.$store.commit("search/setSorting", sortVal);

            const { path } = this.$route;
            const query = { ...this.$route.query };

            const { sortDefault } = config;
            if (config.sortingOptions[sortVal] && sortVal !== sortDefault) {
                query["sort"] = sortVal;
            } else {
                delete query["sort"];
            }
            delete query["page"];

            this.$router.replace({ path, query });
        },
        changePage(pageNo) {
            this.$store.commit("search/setCurrentPage", pageNo);

            const { path } = this.$route;
            const query = { ...this.$route.query };

            if (pageNo != 1) query["page"] = pageNo;
            else delete query["page"];

            this.$router.replace({ path, query });
        },
        changePerPage(event) {
            const perPage = +event.target.value;
            const { path } = this.$route;
            const query = { ...this.$route.query };

            const pageSize = config.products?.perPage;

            if (perPage != pageSize) query["page-size"] = perPage;
            else delete query["page-size"];
            delete query["page"];

            this.$router.replace({ path, query });
            this.$store.commit("search/setProductsPerPage", perPage);
        },
        goToPage(page) {
            const { path } = this.$route;
            const query = { ...this.$route.query };

            if (page != 1) query["page"] = page;
            else delete query["page"];

            return { path, query };
        },
        // pagination
        handleLayout: function (layout) {
            this.layout = layout;
        },
        handlePerPage: function (count) {
            this.perPage = count;
        },
        handleSortBy: function (sort) {
            this.sortBy = sort;
        },
        // pagination
    },
    metaInfo() {
        return {
            title: this.$t("search"),
            link: [
                {
                    rel: "canonical",
                    href:
                        config.app_url +
                        (/\/$/.test(this.$route.fullPath)
                            ? this.$route.fullPath
                            : this.$route.fullPath.replace(/^\//, "") + "/"),
                },
            ],
        };
    },
};
</script>